import React, { ReactNode } from "react";
import { useResponsiveLayout } from "../../hooks/useResponsive";

import "./static-navigation.scss";

type Props = {
  title: string;
  children: ReactNode;
};

const StaticNavigation = ({ title, children }: Props) => {
  const { showCarouselContent } = useResponsiveLayout();

  return (
    <div className={"static-wrapper"}>
      <h1 className={"static-title"}>{title}</h1>
      {showCarouselContent && (
        <div className={"static-content"}>{children}</div>
      )}
    </div>
  );
};
export default StaticNavigation;
