import { graphql, PageProps } from "gatsby";
import React, { createContext, useEffect, useState } from "react";

import PrivacyPolicyNavigation from "../components/privacy-policy/PrivacyPolicyNavigation.component";
import { Layout } from "../components/layout/Layout.component";

import {
  ContentBlock,
  JsonContent,
} from "../shared/contentful/contentful.types";
import { PageContext, SEOContent } from "../shared/page.types";
import PrivacyPolicyContent from "../components/privacy-policy/PrivacyPolicyContent.component";
import PrivacyPolicyIntro from "../components/privacy-policy/PrivacyPolicyIntro.component";

type Props = {
  pageData: {
    title: string;
    seo: SEOContent;
    intro: JsonContent;
    content: ContentBlock[];
  };
};

export const PrivacyPolicyContext = createContext<PageContext>({
  title: "",
  children: <></>,
});

const PrivacyPolicyPage = ({
  data: { pageData },
  location: { href },
}: PageProps<Props>) => {
  const { title, content, seo, intro } = pageData;

  const [contextValue, setContextValue] = useState<PageContext>({
    title: "",
    children: <></>,
  });

  useEffect(() => {
    setContextValue({
      title,
      children: <PrivacyPolicyIntro intro={intro} />,
    });
  }, []);

  const seoProps = {
    ...seo,
    url: href,
  };

  return (
    <PrivacyPolicyContext.Provider value={contextValue}>
      <Layout pageNavigation={PrivacyPolicyNavigation} seo={seoProps}>
        <PrivacyPolicyContent content={content} />
      </Layout>
    </PrivacyPolicyContext.Provider>
  );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query privacyPolicyQuery($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    pageData: contentfulLayoutAllPages(
      identifier: { eq: "page_privacy-policy" }
      node_locale: { eq: $language }
    ) {
      title
      intro {
        raw
      }
      content {
        ...contentBlock
      }
      seo {
        ...seo
      }
    }
  }
`;
