import React, { ReactNode } from "react";
import { Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import { ContentBlock } from "../../shared/contentful/contentful.types";

import "./privacy-policy.scss";

type Props = {
  content: ContentBlock[];
};

const renderOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: ReactNode, children: ReactNode) => (
      <p className={"privacy-policy_content-text"}>{children}</p>
    ),
    [BLOCKS.HEADING_2]: (_node: ReactNode, children: ReactNode) => (
      <h2 className={"privacy-policy_content-h2"}>{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (_node: ReactNode, children: ReactNode) => (
      <h3 className={"privacy-policy_content-h3"}>{children}</h3>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: (node: ReactNode) => <strong>{node}</strong>,
  },
};

const PrivacyPolicyContent = ({ content }: Props) => {
  return (
    <div className="boxed-container privacy-policy_content">
      {content.map((c) => renderRichText(c.content, renderOptions))}
    </div>
  );
};
export default PrivacyPolicyContent;
