import { BLOCKS } from "@contentful/rich-text-types";
import React, { ReactNode } from "react";
import { JsonContent } from "../../shared/contentful/contentful.types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

type Props = {
  intro: JsonContent;
};

const renderOptions = () => {
  return {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node: ReactNode, children: ReactNode) => (
        <p className={""}>{children}</p>
      ),
    },
  };
};

const PrivacyPolicyIntro = ({ intro }: Props) => {
  return <>{renderRichText(intro, renderOptions())}</>;
};
export default PrivacyPolicyIntro;
